import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import reactQueryClient from 'app/api/react-query/react-query-client';
import ArrowDownIcon from 'app/components/icons/ArrowDownIcon';
import ArrowUpIcon from 'app/components/icons/ArrowUpIcon';
import { SelectedHotel, UserPermission } from 'app/features/user/types';
import colors from 'app/styles/colors';
import { useState } from 'react';

interface Props {
  hotelName: string;
  userPermissions?: UserPermission[];
  setCurrentSelectedHotel: (data: SelectedHotel) => void;
}

function LeftItems({ hotelName, userPermissions, setCurrentSelectedHotel }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box id="top-nav-left" data-testid="top-nav-left">
      <Button
        id="select-hotel-menu-button"
        data-testid="select-hotel-menu-button"
        aria-label="select hotel menu button"
        aria-controls={open ? 'select-hotel-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleButtonClick}
        sx={{
          backgroundColor: colors.white,
          color: colors.grey[700],
          borderRadius: '4px',
          px: '10px',
          py: '6px',
          display: 'flex',
          alignItems: 'center',
          height: '32px',
          borderWidth: '1px',
          borderColor: colors.grey[200],
          ':hover': {
            backgroundColor: colors.white,
            color: colors.secondary,
            borderColor: colors.grey[600],
          },
        }}
      >
        <Typography color={colors.grey[700]} fontSize="16px" fontWeight="450" letterSpacing=".16px">
          {hotelName}
        </Typography>
        <Box sx={{ ml: 1 }}>
          {open ? <ArrowUpIcon color="colors.grey[600]" /> : <ArrowDownIcon color="colors.grey[600]" />}
        </Box>
      </Button>

      <Menu
        id="select-hotel-menu"
        data-testid="select-hotel-menu"
        MenuListProps={{
          'aria-labelledby': 'select-hotel-menu-button',
          sx: { p: 0 },
          role: 'none',
          tabIndex: -1,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        elevation={0}
        transitionDuration={{ exit: 0, enter: 200, appear: 200 }}
        PaperProps={{
          sx: {
            width: '260px',
            borderRadius: '4px',
            mt: 0.5,
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
            backgroundColor: colors.white,
          },
          role: 'dialog',
          'aria-label': 'Datepicker',
          tabIndex: -1,
        }}
      >
        {open && (
          <Box
            data-testid="select-hotel-menu-wrapper"
            sx={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 2 }}
          >
            {userPermissions?.map((item) => {
              const { hotel, role } = item;
              const { id, name } = hotel || { id: '', name: '' };
              return (
                <MenuItem
                  onClick={() => {
                    setCurrentSelectedHotel({
                      id,
                      name,
                      userHotelRole: role,
                    });
                    handleMenuClose();
                    reactQueryClient.resetQueries();
                  }}
                  data-testid={`select-hotel-menu-${name}-${role}`}
                  key={id}
                  sx={{
                    ':hover': { backgroundColor: colors.grey[200] },
                  }}
                  disabled={name === hotelName}
                >
                  <Typography
                    variant="subtitle1"
                    fontSize={14}
                    letterSpacing="0.02em"
                    fontWeight={450}
                    sx={{ py: 1 }}
                  >
                    {name || role}
                  </Typography>
                </MenuItem>
              );
            })}
          </Box>
        )}
      </Menu>
    </Box>
  );
}

export default LeftItems;
